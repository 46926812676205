import { loader } from "../../utils/google-api-loader";
import React, { useRef, useEffect, useState, Fragment } from "react";
import { HighlightOff } from "@material-ui/icons";
import CommonInput from "../CommonComponent/CommonInput";
import infoCircle from "../../../images/bp/dashboard/info_circle.svg";

const AutocompletePlaces = ({
  setFieldValue,
  setField,
  values,
  placeholder = "Search Your Location",
  className,
  style,
  handleBlur,
  handleChange,
  outerClass = "flex flex-col w-full mr-2",
  fields = [
    "address_components",
    "formatted_address",
    "geometry",
    "name",
    "place_id",
    "plus_code",
    "vicinity",
    "url",
  ],
  showMap,
  touched,
  errors,
  setFullAddField,
  legendName = "House / Flat / Floor No.",
  styleMap,
  innerDiv = "flex flex-col",
  dropdownStyle,
  mapClass,
  defaultValue,
  showExtra,
  showLegend = true,
  newInputStyle,
  newContainerStyle,
  fullAddressRequired = true,
}) => {
  const autoCompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [address, setAddress] = useState("");
  const [isFocussed, setIsFocussed] = useState(false);
  const [locality, setLocality] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [currDiv, setCurrDiv] = useState();
  const errorClass = "border border-red-300 hover:border hover:border-red-300";

  useEffect(() => {
    setAddress(values[setField]?.formatted_address);
    if (showExtra) {
      const cityResult = values[setField]?.address_components?.find(component =>
        component?.types?.includes("locality")
      );
      setCity(cityResult ? cityResult?.long_name : "");

      const sublocality_level_1 = values[
        setField
      ]?.address_components?.find(component =>
        component?.types?.includes("sublocality_level_1")
      );

      const sublocality_level_2 = values[
        setField
      ]?.address_components?.find(component =>
        component?.types?.includes("sublocality_level_2")
      );

      const sublocality_level_3 = values[
        setField
      ]?.address_components?.find(component =>
        component?.types?.includes("sublocality_level_3")
      );
      setLocality(
        sublocality_level_1
          ? sublocality_level_1?.long_name
          : sublocality_level_2
          ? sublocality_level_2?.long_name
          : sublocality_level_3?.long_name
      );

      const pincodeResult = values[
        setField
      ]?.address_components?.find(component =>
        component?.types?.includes("postal_code")
      );
      setPincode(pincodeResult ? pincodeResult?.long_name : "");
    }
  }, [values[setField]?.formatted_address]);

  useEffect(() => {
    loader.load().then(async () => {
      const autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          fields: fields,
        }
      );

      const map = showMap
        ? new window.google.maps.Map(mapRef.current, {
            streetViewControl: false,
          })
        : null;

      let marker = showMap
        ? new google.maps.Marker({
            map,
            position: values[setField]?.geometry?.location,
          })
        : null;

      if (showMap) {
        map?.setCenter(
          !defaultValue
            ? values[setField]?.geometry?.location
            : { lat: 20.898954722875782, lng: 79.57191043672469 }
        );

        map?.setZoom(!defaultValue ? 15 : 5);
      }

      const geocode = showMap ? new google.maps.Geocoder() : null;

      showMap ? autoComplete.bindTo("bounds", map) : null;

      autoComplete.addListener("place_changed", () => {
        const place = autoComplete.getPlace();
        delete place.html_attributions;
        delete place?.geometry?.viewport;
        place["geometry"]["viewport"] = {};
        setFieldValue(setField, place);
        setAddress(place?.formatted_address);

        if (showMap) {
          marker.setPosition(place.geometry.location);

          map.setCenter(place.geometry.location);
          map.setZoom(15);
        }
      });

      if (showMap)
        map?.addListener("click", event => {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();

          geocode.geocode(
            {
              location: { lat, lng },
            },
            (result, status) => {
              if (status == "OK" && result[0]) {
                setFieldValue(setField, result[0]);
                setAddress(result[0]?.formatted_address);
              }
            }
          );

          marker.setPosition({ lat: lat, lng: lng });

          map?.setCenter({ lat, lng });
          map?.setZoom(15);
        });
    });
  }, []);

  return (
    <Fragment>
      <div className="flex flex-col w-full">
        <div className={`relative ${outerClass}`}>
          <div
            style={style}
            className={`flex placeholder-black w-full h-12 bg-white items-center relative
              rounded-l-md font-normal font-roboto text-sm px-2 border rounded border-grey-1009 ${className} ${
              errors?.[setField]
                ? errorClass
                : `hover:border ${
                    showLegend
                      ? "hover:border-green-600"
                      : "hover:border-green-2100"
                  } focus:border-blue-700`
            }`}
          >
            <label
              className={`${
                isFocussed || address?.length > 0 ? `top-0 -mt-2 bg-white` : ``
              } absolute transition-all duration-300 text-xs ml-3  ${
                address?.length > 0 ? `` : `pointer-events-none`
              } ${
                address?.length > 0 || isFocussed
                  ? `text-black-400`
                  : `text-black-500`
              } ${errors?.[setField] ? "text-red-300" : ""}`}
              style={{
                backgroundColor: "transparent",
              }}
            >
              {placeholder}
            </label>
            <input
              id="location-input"
              className="w-full px-2"
              name={setField}
              ref={autoCompleteRef}
              placeholder=""
              type="text"
              value={address}
              onChange={e => setAddress(e.target.value)}
              onFocus={() => setIsFocussed(true)}
              onBlur={() => setIsFocussed(false)}
              style={{ dropdownStyle, ...newInputStyle }}
            />
            <HighlightOff
              onClick={() => {
                setAddress("");
                setFieldValue(setField, "");
              }}
              className="cursor-pointer text-black-400"
            />
          </div>
          {values[setField] ? (
            <Fragment>
              {fullAddressRequired && (
                <div className="flex items-center gap-2 items-start py-4">
                  <img src={infoCircle} />
                  <p className="text-black-400 text-sm md:text-base">
                    Fill in your details which was not in search location
                  </p>
                </div>
              )}
              {fullAddressRequired && (
                <>
                  {newInputStyle && (
                    <p
                      className="mb-2 ml-1 font-semibold"
                      style={{ color: "#00000059" }}
                    >
                      Office / Floor no.
                    </p>
                  )}
                  <CommonInput
                    name={setFullAddField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[setFullAddField]}
                    stylesContainer={{
                      maxWidth: "100%",
                      marginRight: "20px",
                      marginBottom: "20px",
                      ...newContainerStyle,
                    }}
                    inputStyle={{
                      ...style,
                      ...newInputStyle,
                    }}
                    fieldsetStyle={{
                      ...style,
                    }}
                    legendName={!newInputStyle && legendName}
                    showLegend={!showLegend ? false : true}
                    error={touched[setFullAddField] && errors[setFullAddField]}
                    errorMsg={errors[setFullAddField]}
                    touched={touched[setFullAddField]}
                  />
                </>
              )}
              {showExtra ? (
                <Fragment>
                  {!showLegend && (
                    <p
                      className="mb-2 ml-1 font-semibold"
                      style={{ color: "#00000059" }}
                    >
                      Locality
                    </p>
                  )}
                  <CommonInput
                    name="Locality"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={locality}
                    stylesContainer={{
                      maxWidth: "100%",
                      marginRight: "20px",
                      marginBottom: "20px",
                      ...newContainerStyle,
                    }}
                    legendName="Locality"
                    showLegend={!showLegend ? false : true}
                    disabled
                    inputStyle={{
                      ...style,
                      ...newInputStyle,
                    }}
                    fieldsetStyle={{
                      ...style,
                    }}
                  />
                  <div className={innerDiv}>
                    <div className="w-1/2 flex flex-col pr-6">
                      {!showLegend && (
                        <p
                          className="mb-2 ml-1 font-semibold"
                          style={{ color: "#00000059" }}
                        >
                          City
                        </p>
                      )}
                      <CommonInput
                        name="City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={city}
                        stylesContainer={{
                          maxWidth: "100%",
                          marginRight: "20px",
                          ...(!showMap && { marginBottom: "20px" }),
                          ...newContainerStyle,
                        }}
                        legendName="City"
                        showLegend={!showLegend ? false : true}
                        disabled
                        inputStyle={{
                          ...style,
                          ...newInputStyle,
                        }}
                        fieldsetStyle={{
                          ...style,
                        }}
                      />
                    </div>
                    <div className="w-1/2 flex flex-col">
                      {!showLegend && (
                        <p
                          className="mb-2 ml-1 font-semibold"
                          style={{ color: "#00000059" }}
                        >
                          Pincode
                        </p>
                      )}
                      <CommonInput
                        name="Pincode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={pincode}
                        stylesContainer={{
                          maxWidth: "100%",
                          ...(!showMap &&
                            !showExtra && { marginRight: "20px" }),
                          ...(!showMap && { marginBottom: "20px" }),
                          ...newContainerStyle,
                        }}
                        legendName="Pincode"
                        showLegend={!showLegend ? false : true}
                        disabled
                        inputStyle={{
                          ...style,
                          ...newInputStyle,
                        }}
                        fieldsetStyle={{
                          ...style,
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </div>
        {errors?.[setField] && (
          <div className="mt-1 text-xs text-red-300 ">{errors?.[setField]}</div>
        )}
      </div>
      {showMap ? (
        <div
          className={mapClass}
          ref={mapRef}
          style={{
            width: "100%",
            minHeight: "200px",
            marginTop: "20px",
            borderRadius: "4px",
            ...styleMap,
          }}
        ></div>
      ) : null}
    </Fragment>
  );
};

export default AutocompletePlaces;
