import React from "react";
import uploading from "../../../images/icons/upload_new.svg";
import mob_Upload from "../../../images/icons/mob_Upload.svg";
import init_upload from "../../../images/icons/init_upload.svg";
import CircularLoading from "../../../common/components/CircularLoading/CircularLoading";

export default function UploadView(props) {
  const { subInst, subtext, error, initialImg, imgClasses, loading } = props;
  let innerText;

  const imgSrcSelector = () => {
    if (loading) {
      innerText = "Uploading…";
      return uploading;
    } else {
      innerText = subtext;
      return init_upload;
    }
  };

  return (
    <>
      <div className="block md:hidden">
        <img src={mob_Upload} alt="file_uplaod" className={""} />
      </div>
      <div
        className={
          "hidden md:flex flex-col items-center justify-center text-center "
        }
      >
        <img
          src={imgSrcSelector()}
          alt="file_uplaod"
          className={imgClasses ? imgClasses : "mx-auto h-8 items-center "}
        />
        {!loading ? (
          <p
            className={
              subInst
                ? "text-black-1001 font-roboto font-normal text-xs mx-auto "
                : "hidden "
            }
          >
            {subInst || ""}
          </p>
        ) : null}
        {error && error}
        <p
          className={
            loading
              ? "text-black-500 "
              : "text-black-700 font-roboto font-bold text-sm mt-5 "
          }
        >
          {!loading ? innerText : "Uploading…"}
        </p>
      </div>
    </>
  );
}
