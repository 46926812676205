import React, { useState, useEffect, Fragment } from "react";
import UploadImgComponent from "../../components/CommonComponent/UploadImgComponent";
import UploadView from "../../components/CommonComponent/UploadView";
import upload_error from "../../../images/icons/upload_error.svg";
import mb_checked from "../../../images/icons/mb_checkNonTrans.svg";
import mb_reupload from "../../../images/icons/mb_upload.svg";
import err1 from "../../../images/icons/err1.svg";
import pdf_solid from "../../../images/icons/pdf.svg";
import csv from "../../../images/icons/csv.svg";
import info_hollow from "../../../images/icons/info_hollow.svg";
import { FailureSvg } from "../../components/PopUp/CommonSvgs";

import {
  useCheckFileAndUpdateError,
  checkFileUploadCriteria,
} from "../../components/CommonComponent/checkFileUploadCriteria";
import mob_Upload from "../../../images/icons/mob_Upload.svg";
import Toast from "../Toast/Toast";
import ProgressBar from "../CustomProgressBar/ProgressBar";

const GlobalUploadComp = ({
  data,
  fileKey = "file",
  imgUrlKey = "imgUrl",
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleChange,
  imgIndex,
  showFullImage,
  initialImg,
  subInst,
  subtext,
  children,
  headerText,
  mbViewOnly = false,
  imgSize,
  limitText,
  accept,
  isNewOne,
  newClass = "h-10 w-full",
  isBookingForm = false,
  docDesign,
  aadhaarDesign,
  docDesignUploadedFileName,
  setDocDesignUploadedFileName,
  ownStyle,
  mainContainerStyle,
  disabled,
  innerOrgStyle = {},
  showProgressBar,
  completed,
  fillerStyles,
  containerStyles,
  progressBarClass,
  orgClass,
  showFileStyle,
  innerOrgClass = "md:flex md:flex-col  md:w-58  md:h-32   md:border md:border-grey-1006 md:overflow-hidden md:bg-grey-1007",
}) => {
  const { file, imgUrl } = data;
  const [fileUploadErrors, setFileUploadErrors] = useState({
    file: "",
  });
  const [loading, setLoading] = useState(false);
  const [isThatPdf, setIsThatPdf] = useState(false);
  const [showErrorSvg, setShowErrorSvg] = useState(false);
  useEffect(() => {
    return () => {
      data.file = [];
      data.imgUrl = [];
      setFileUploadErrors({
        file: "",
      });
    };
  }, []);
  const onChange = async (imgUrl, file, e, imgIndex) => {
    setLoading(true);
    if (isBookingForm)
      setFieldValue("selectedUploadComponentFileKey", `${fileKey}`);
    const isImagePassingCriteria = await checkFileUploadCriteria(file, imgSize);
    const {
      isPdf,
      imageType,
      imageSize,
      imageResolution,
    } = isImagePassingCriteria;
    setIsThatPdf(isPdf);
    let validImage = false;
    validImage =
      imageType &&
      !imageType?.errors &&
      imageSize &&
      !imageSize?.errors &&
      imageResolution &&
      !imageResolution?.errors;

    if (validImage) {
      setFileUploadErrors({ file: "" });
      if (data.file[imgIndex] == "") data.file.splice(imgIndex, 1, file);
      else data.file[imgIndex] = file;
      setFieldValue(`${fileKey}`, data.file);
      //imgUrl
      if (data.imgUrl[imgIndex] == "") data.imgUrl.splice(imgIndex, 1, imgUrl);
      else data.imgUrl[imgIndex] = imgUrl;
      setFieldValue(`${imgUrlKey}`, data.imgUrl);
    } else {
      //if file is invalid
      data.file.splice(imgIndex, 1, "");
      setFieldValue(`${fileKey}`, data.file);
      data.imgUrl[imgIndex] = "";
      setFieldValue(`${imgUrlKey}`, data.imgUrl);

      if (imageResolution?.errors) {
        setFileUploadErrors({ file: imageResolution.errors });
      }
      if (imageType?.errors) {
        setFileUploadErrors({ file: imageType.errors });
      }
      if (imageSize?.errors) {
        setFileUploadErrors({ file: imageSize.errors });
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    deskImageSelector();
  }, [fileUploadErrors.file]);

  const deskImageSelector = () => {
    const csvType = [
      "text/csv",
      "text/plain",
      "application/csv",
      "text/comma-separated-values",
      "application/excel",
      "application/vnd.ms-excel",
      "application/vnd.msexcel",
      "text/anytext",
      "application/octet-stream",
      "application/txt",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    if (isThatPdf) {
      return csvType?.includes(file[0][0]?.type) ? csv : pdf_solid;
    } else if (
      !isThatPdf &&
      !fileUploadErrors.file &&
      fileUploadErrors.file === ""
    ) {
      return imgUrl[imgIndex];
    }
  };

  const isFileLoaded = Array.isArray(file) && file[imgIndex];

  const isImageUploadedLocally = imgUrl[imgIndex] || imgUrl[imgIndex] === "";

  return (
    <>
      {isNewOne ? ( // Bulk upload component
        <div className={`h-full ${mainContainerStyle}`}>
          <div
            className={`w-full font-semibold text-black-400 ${
              docDesign ? `` : `py-1`
            }`}
          >
            {subInst}
          </div>
          <div
            className={
              docDesign
                ? ``
                : `relative w-full bg-blue-1210 ${newClass} flex items-center ${
                    aadhaarDesign ? `` : `px-4`
                  } ${fileUploadErrors.file ? "border border-red-100" : ""}`
            }
          >
            <UploadImgComponent
              disabled={disabled}
              name="file"
              imgIndex={imgIndex}
              onChange={(imgUrl, file, e, imgIndex) => {
                // if (handleChange) {
                //   handleChange(e);
                // }
                onChange(imgUrl, file, e, imgIndex);
              }}
              accept={accept}
              isNewOne
              docDesign
              aadhaarDesign={aadhaarDesign}
            >
              {isFileLoaded && (!docDesign || aadhaarDesign) ? (
                <div className="flex items-center space-x-2">
                  <img
                    className={`z-1 cursor-pointer top-0 ${
                      aadhaarDesign ? ` w-24 h-24 ` : `w-8 h-8`
                    }`}
                    src={aadhaarDesign ? imgUrl[imgIndex] : mb_checked}
                    alt="ok"
                    onClick={e => {
                      if (imgUrl[imgIndex]) {
                        window.open(imgUrl[imgIndex], "_blank");
                      }
                    }}
                  />
                  {!docDesign && (
                    <p className="text-xs hidden md:block">
                      Uploaded Successfully!
                    </p>
                  )}
                  {!docDesign && (
                    <p className="text-xs block md:hidden">Uploaded!</p>
                  )}
                </div>
              ) : (
                <p>{docDesign ? "Upload" : "Select file"}</p>
              )}
            </UploadImgComponent>
          </div>
          {isFileLoaded &&
            (docDesign ? (
              setDocDesignUploadedFileName(file)
            ) : (
              <div className="text-blue-600 text-xs">{file[0][0]?.name}</div>
            ))}
          {fileUploadErrors.file &&
            (docDesign ? (
              Toast("error", fileUploadErrors?.file, "2000", "bottom-right")
            ) : (
              <div className="w-full text-red-100 text-xs">
                {fileUploadErrors.file}
              </div>
            ))}
        </div>
      ) : isBookingForm ? ( // Booking Form image upload component
        <div className={`relative w-full flex items-center justify-center`}>
          <UploadImgComponent
            disabled={disabled}
            name="file"
            imgIndex={imgIndex}
            onChange={(imgUrl, file, e, imgIndex) => {
              onChange(imgUrl, file, e, imgIndex);
            }}
            accept={accept}
            isNewOne
          >
            {isFileLoaded ? (
              <div className="flex items-center justify-center w-40 h-28">
                <img
                  className="cursor-pointer"
                  style={{
                    maxHeight: "120px",
                  }}
                  src={deskImageSelector()}
                  alt="ok"
                  onClick={e => {
                    if (imgUrl[imgIndex]) {
                      window.open(imgUrl[imgIndex], "_blank");
                    }
                  }}
                />
                <div
                  className="absolute"
                  style={{
                    top: "-6px",
                    right: "-8px",
                  }}
                >
                  <img
                    className={
                      (!showFullImage ? "w-10 h-10" : " ml-0") + " block"
                    }
                    src={mb_reupload}
                    alt="re-upload"
                  />
                </div>
              </div>
            ) : children && !fileUploadErrors.file ? (
              children
            ) : children && fileUploadErrors.file ? (
              <div className="w-40 flex items-center flex-col">
                <img
                  className={`cursor-pointer mb-2 h-10 w-10`}
                  src={err1}
                  alt="error"
                  onClick={e => {
                    if (imgUrl[imgIndex]) {
                      window.open(imgUrl[imgIndex], "_blank");
                    }
                  }}
                />
                <div className="w-35 text-red-100 text-xs">
                  {fileUploadErrors.file}
                </div>
                <div
                  className="absolute"
                  style={{
                    top: "-4px",
                    right: "-8px",
                  }}
                >
                  <img
                    className={
                      (!showFullImage ? "w-10 h-10" : " ml-0") + " block"
                    }
                    src={mb_reupload}
                    alt="re-upload"
                  />
                </div>
              </div>
            ) : (
              <UploadView
                loading={loading}
                imgClasses={
                  (loading ? "mt-12 " : "mt-6 ") + "block h-9 w-12 mb-8 "
                }
                initialImg={initialImg} // initial image
                subInst={subInst}
                subtext={subtext}
                error={<p className="text-red-100">{fileUploadErrors.file}</p>}
              />
            )}
          </UploadImgComponent>
        </div>
      ) : (
        // original upload component
        <div className={orgClass}>
          <div
            style={innerOrgStyle}
            className={`block w-auto mx-auto relative ${
              mbViewOnly ? "" : innerOrgClass
            }`}
          >
            {headerText ? headerText : ""}

            {!showProgressBar ? (
              <div
                className={`${ownStyle}
                ${showFullImage ? "w-32 " : "w-24 "}
                "relative"${
                  mbViewOnly
                    ? ""
                    : " md:w-auto  md:flex md:flex-row md:justify-between "
                }
              `}
              >
                {isImageUploadedLocally && (
                  <>
                    {!isThatPdf && fileUploadErrors.file ? (
                      <div
                        className={`w-28 h-20 ml-10 pt-1 mb-4 mt-4 cursor-pointer hidden ${
                          mbViewOnly ? "" : "md:block"
                        }`}
                      >
                        {" "}
                        <FailureSvg />
                      </div>
                    ) : (
                      <img
                        className={
                          (fileUploadErrors.file
                            ? "w-28 h-20 ml-10 pt-1 mb-4  "
                            : "w-35 h-24 ml-6  ") +
                          " mt-6 cursor-pointer hidden" +
                          (mbViewOnly ? "" : " md:block ")
                        }
                        src={deskImageSelector()}
                        onClick={e => {
                          if (imgUrl[imgIndex]) {
                            window.open(imgUrl[imgIndex], "_blank");
                          }
                        }}
                        alt=""
                      />
                    )}
                    {/* initial upload for mobile */}
                    {!showFullImage && (
                      <>
                        {/* mb designs */}
                        <div
                          className={`z-10 cursor-pointer relative block ${
                            mbViewOnly ? "" : "md:hidden"
                          }`}
                        >
                          <img
                            className={`block ${mbViewOnly ? "" : "md:hidden"}`}
                            src={mob_Upload}
                            onClick={e => {
                              if (imgUrl[imgIndex]) {
                                window.open(imgUrl[imgIndex], "_blank");
                              }
                            }}
                            alt=""
                          />
                          {!fileUploadErrors.file && (
                            <img
                              className="z-50 cursor-pointer absolute top-0 mt-3 mx-auto ml-3  h-12 w-12"
                              src={mb_checked}
                              alt="ok"
                              onClick={e => {
                                if (imgUrl[imgIndex]) {
                                  window.open(imgUrl[imgIndex], "_blank");
                                }
                              }}
                            />
                          )}
                          {fileUploadErrors.file && (
                            <img
                              className={`z-50 cursor-pointer absolute top-0 mt-3 mx-auto ml-3 h-12 w-12 ${
                                mbViewOnly ? "" : "md:hidden"
                              }`}
                              src={err1}
                              alt="error"
                              onClick={e => {
                                if (imgUrl[imgIndex]) {
                                  window.open(imgUrl[imgIndex], "_blank");
                                }
                              }}
                            />
                          )}
                          {fileUploadErrors.file && (
                            <>
                              <div
                                className={`inline-flex ml-1 block ${
                                  mbViewOnly ? "" : "md:hidden"
                                }`}
                              >
                                <p className="mt-1 text-red-100 font-light text-xs text-left ml-1">
                                  {" "}
                                  Invalid
                                </p>
                                <img
                                  className="ml-2 mt-1 w-4 h-4"
                                  src={info_hollow}
                                  alt="info"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {/* imageView mobileScreens */}
                    {showFullImage && (
                      <div
                        className={
                          (isThatPdf || fileUploadErrors.file
                            ? "border border-grey-1006 "
                            : "") +
                          " w-32 h-22  block " +
                          (mbViewOnly ? "" : " md:hidden ")
                        }
                      >
                        <img
                          className={
                            (!isThatPdf && fileUploadErrors.file
                              ? " w-10 h-10 mt-1 mx-auto "
                              : "hidden ") + " cursor-pointer  "
                          }
                          src={err1}
                          onClick={e => {
                            if (imgUrl[imgIndex]) {
                              window.open(imgUrl[imgIndex], "_blank");
                            }
                          }}
                          alt="ImageView"
                        />

                        {/* error msg for mb */}
                        <p
                          className={
                            !isThatPdf && fileUploadErrors.file
                              ? "block mt-1 text-red-100 font-light text-xs text-left ml-1"
                              : "hidden"
                          }
                        >
                          <img
                            className="absolute right-0 mt-4 mr-1 w-4 h-4"
                            src={info_hollow}
                            alt="info"
                          />
                          {/* {fileUploadErrors.file}
                           */}
                          Invalid file , please choose another
                        </p>

                        {/* if pdf */}
                        <img
                          className={
                            (isThatPdf
                              ? "mx-auto w-16 h-12 mt-1 "
                              : " hidden") +
                            " cursor-pointer" +
                            (mbViewOnly ? "" : " block md:hidden ")
                          }
                          src={pdf_solid}
                          onClick={e => {
                            if (imgUrl[imgIndex]) {
                              window.open(imgUrl[imgIndex], "_blank");
                            }
                          }}
                          alt="ImageView"
                        />
                        {!loading && !fileUploadErrors.file ? (
                          <p
                            className={
                              (isThatPdf
                                ? "text-center mt-2 "
                                : "ml-5  mt-2  hidden ") +
                              " text-left text-black-50  font-normal truncate mb-1 px-2"
                            }
                          >
                            {isFileLoaded ? file[imgIndex][0].name : null}
                          </p>
                        ) : null}
                        {/* if its neither pdf nor error */}
                        <img
                          className={
                            (!isThatPdf && !fileUploadErrors.file
                              ? "h-full w-full  "
                              : "hidden ") + " cursor-pointer "
                          }
                          src={imgUrl[imgIndex]}
                          onClick={e => {
                            if (imgUrl[imgIndex]) {
                              window.open(imgUrl[imgIndex], "_blank");
                            }
                          }}
                          alt="ImageView"
                        />
                      </div>
                    )}
                  </>
                )}
                {!isImageUploadedLocally && (
                  <>
                    <UploadImgComponent
                      disabled={disabled}
                      name="file"
                      imgIndex={imgIndex}
                      onChange={(imgUrl, file, e, imgIndex) => {
                        // if (handleChange) {
                        //   handleChange(e);
                        // }
                        onChange(imgUrl, file, e, imgIndex);
                      }}
                      accept={accept}
                    >
                      {<div className="border-b border-dashed border-blue-900">
                        {children}
                      </div> ? (
                        children
                      ) : (
                        <UploadView
                          loading={loading}
                          imgClasses={
                            (loading ? "mt-12 " : "mt-6 ") +
                            "block h-9 w-12 mb-8 "
                          }
                          initialImg={initialImg} // initial image
                          subInst={subInst}
                          subtext={subtext}
                          error={
                            <p className="text-red-100 ">
                              {fileUploadErrors.file}
                            </p>
                            // )
                          }
                        />
                      )}
                    </UploadImgComponent>
                  </>
                )}
                {/* reupload buttons */}
                {isImageUploadedLocally && (
                  <div
                    className={
                      (fileUploadErrors.file
                        ? mbViewOnly
                          ? ""
                          : " md:mt-1 "
                        : mbViewOnly
                        ? ""
                        : " md:mt-10 ") +
                      (!showFullImage ? " -mr-6 " : " mr-0.5 ") +
                      " z-50 -mt-4 absolute top-0 right-0  "
                    }
                  >
                    {/* for mobile */}
                    <UploadImgComponent
                      custom={true}
                      loading={loading}
                      name="file"
                      imgIndex={imgIndex}
                      onChange={(imgUrl, file, e, imgIndex) => {
                        onChange(imgUrl, file, e, imgIndex);
                      }}
                      accept={accept}
                    >
                      <>
                        {/* reUpload for mb/ */}
                        <div
                          className={
                            (!showFullImage ? "-ml-17 mt-2 " : "-mt-1 ml-20") +
                            " z-50 block" +
                            (mbViewOnly ? "" : " md:hidden ")
                          }
                        >
                          <img
                            className={
                              (!showFullImage ? "w-10 h-10  " : " ml-6 ") +
                              " block"
                            }
                            src={mb_reupload}
                            alt="re-upload"
                          />
                        </div>
                        {/* reUpload for desktop */}
                        {!showProgressBar ? (
                          <div
                            className={
                              (fileUploadErrors.file ? "mt-14 " : "mt-8 ") +
                              "hidden" +
                              (mbViewOnly ? "" : " md:block ") +
                              "font-roboto mr-12 text-sm font-medium underline"
                            }
                          >
                            {mbViewOnly ? "" : " Reupload "}
                          </div>
                        ) : null}
                      </>
                    </UploadImgComponent>
                  </div>
                )}
              </div>
            ) : null}
            {showProgressBar ? (
              <Fragment>
                {!loading && !fileUploadErrors.file && isFileLoaded ? (
                  <div style={showFileStyle} className="absolute truncate">
                    <p
                      className={`text-center text-white text-xs py-1  font-normal truncate px-2 hidden ${
                        mbViewOnly ? "" : "md:block"
                      }`}
                    >
                      {isFileLoaded ? file[imgIndex][0].name : null}
                    </p>
                  </div>
                ) : null}
                <UploadImgComponent
                  disabled={disabled}
                  name="file"
                  imgIndex={imgIndex}
                  onChange={(imgUrl, file, e, imgIndex) => {
                    // if (handleChange) {
                    //   handleChange(e);
                    // }
                    onChange(imgUrl, file, e, imgIndex);
                  }}
                  accept={accept}
                >
                  {children ? (
                    children
                  ) : (
                    <UploadView
                      loading={loading}
                      imgClasses={
                        (loading ? "mt-12 " : "mt-6 ") + "block h-9 w-12 mb-8 "
                      }
                      initialImg={initialImg} // initial image
                      subInst={subInst}
                      subtext={subtext}
                      error={
                        <p className="text-red-100 ">{fileUploadErrors.file}</p>
                        // )
                      }
                    />
                  )}
                </UploadImgComponent>
                {isImageUploadedLocally ? (
                  <div className={progressBarClass}>
                    <ProgressBar
                      completed={completed}
                      propContainerStyles={containerStyles}
                      propFillerStyles={fillerStyles}
                    />
                    <p>{`${completed}%`}</p>
                  </div>
                ) : null}
              </Fragment>
            ) : null}
            {!showProgressBar || fileUploadErrors?.file ? (
              <p
                className={`hidden ${
                  mbViewOnly ? "" : "md:block"
                } text-red-100 mt-3 text-center`}
              >
                {fileUploadErrors.file}
              </p>
            ) : null}
            {!showProgressBar && !loading && !fileUploadErrors.file ? (
              <p
                className={`ml-5 px-2  mt-2 text-left text-black-50  font-normal truncate hidden ${
                  mbViewOnly ? "" : "md:block"
                }`}
              >
                {isFileLoaded ? file[imgIndex][0].name : null}
              </p>
            ) : null}
          </div>
          {!showProgressBar ? (
            <p
              className={`hidden ${
                mbViewOnly ? "" : "md:block md:max-w-full"
              } w-75  mx-auto font-roboto font-normal text-xs text-center text-black-50 break-words`}
            >
              {limitText ||
                "(upload file upto 5MB in jpeg, jpg, png, csv and pdf format)"}
            </p>
          ) : null}
        </div>
      )}
    </>
  );
};
export default React.memo(GlobalUploadComp);
