import { Loader } from "@googlemaps/js-api-loader";
import config from "../../../data/SiteConfig";

export const loader = new Loader({
  apiKey: "AIzaSyDNRsGmBPERkrk9tN6HUbrYG7bqTdccXgc",
  libraries: ["places"],
});


// account details- 
// username- fincitydev@gmail.com
// password- pk007##**