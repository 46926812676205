import React, { useEffect, useRef } from "react";
import NewSpinner from "../../../common/components/NewSpinner/NewSpinner";

const CommonInfiniteScroll = ({
  dependencyList,
  onChangeVisibility,
  isDone,
  isEmptyData,
}) => {
  const loader = useRef(null);

  const handleObserver = entities => {
    const target = entities[0];

    onChangeVisibility(target);
  };

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 0.8,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => observer.unobserve(loader.current);
  }, dependencyList || []);

  return (
    <div>
      <div
        className="loading flex items-center justify-center h-14"
        ref={loader}
      >
        {!isDone && !isEmptyData && <NewSpinner />}
        {isDone && (
          <div className="flex items-center justify-center">
            No more data to show
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonInfiniteScroll;
