import React from "react";
import get from "lodash/get";

export function checkResolution(url, type) {
  return new Promise((resolve, reject) => {
    if (!url) resolve(false);
    let new_img = new Image();
    new_img.src = url;
    new_img.style.visibility = "hidden";
    document.body.appendChild(new_img);
    function cleanup() {
      new_img.removeEventListener("load", onLoadhandler);
      if (new_img.remove) {
        new_img.remove();
      }
    }
    const onLoadhandler = event => {
      try {
        const img_width = event.target.naturalWidth;
        const img_height = event.target.naturalHeight;

        if (
          img_width >= 280 &&
          img_width <= 32000 &&
          img_height >= 200 &&
          img_height <= 32000
        ) {
          resolve(true);
          return;
        }
        resolve(false);
      } catch (e) {
        resolve(false);
      } finally {
        cleanup();
      }
    };

    new_img.addEventListener("load", onLoadhandler);
  });
}

export async function checkFileUploadCriteria(files, imgSize) {
  if (!files)
    return {
      imageType: {
        errors: true,
      },
      imageResolution: {
        errors: true,
      },
      imageSize: {
        errors: true,
      },
    };

  const fileObj = files[0];

  const imgUrl = window.URL.createObjectURL(fileObj);

  const { size, type } = fileObj;
  const csvType = [
    "text/csv",
    "text/plain",
    "application/csv",
    "text/comma-separated-values",
    "application/excel",
    "application/vnd.ms-excel",
    "application/vnd.msexcel",
    "text/anytext",
    "application/octet-stream",
    "application/txt",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  const validFileTypes = [
    { type: "image/jpeg", sizelimit: imgSize || 5000000 },
    { type: "image/jpg", sizelimit: imgSize || 5000000 },
    { type: "image/png", sizelimit: imgSize || 5000000 },
    { type: "application/pdf", sizelimit: imgSize || 5000000 },
    { type: "text/csv", sizelimit: imgSize || 5000000 },
    { type: "text/plain", sizelimit: imgSize || 5000000 },
    { type: "application/csv", sizelimit: imgSize || 5000000 },
    { type: "application/excel", sizelimit: imgSize || 5000000 },
    { type: "application/vnd.ms-excel", sizelimit: imgSize || 5000000 },
    { type: "application/vnd.msexcel", sizelimit: imgSize || 5000000 },
    { type: "application/csv", sizelimit: imgSize || 5000000 },
    { type: "text/anytext", sizelimit: imgSize || 5000000 },
    { type: "application/octet-stream", sizelimit: imgSize || 5000000 },
    { type: "application/txt", sizelimit: imgSize || 5000000 },
    {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      sizelimit: imgSize || 5000000,
    },
  ];

  let imageTypeValidIndex = validFileTypes.findIndex(
    elem => elem.type === type
  );
  let imageTypeValid = imageTypeValidIndex !== -1;

  if (imageTypeValid) {
    const validFileTypeobj = validFileTypes[imageTypeValidIndex];
    if (size > validFileTypeobj.sizelimit) {
      return {
        imageSize: {
          errors: `File size should be less than or equal to ${
            validFileTypeobj.sizelimit / 1000000
          }MB`,
        },
      };
    }

    let isResolutionRight;
    let isPdf = false;
    if (imageTypeValid) {
      if (
        validFileTypes[imageTypeValidIndex].type !== "application/pdf" &&
        !csvType.includes(validFileTypes[imageTypeValidIndex].type)
      ) {
        isResolutionRight = await checkResolution(imgUrl, "others");
      } else {
        isResolutionRight = true;
        isPdf = true;
      }
    }
    if (!isResolutionRight) {
      return {
        imageResolution: {
          errors: `File width should be > 280px & < 32000px and height should be > 200px and < 32000px`,
        },
      };
    }
    // image passed all the criteria
    return {
      isPdf: isPdf,
      imageType: {
        errors: false,
      },
      imageResolution: {
        errors: false,
      },
      imageSize: {
        errors: false,
      },
    };
  }
  return {
    imageType: {
      errors: `File type is not valid, only jpeg, jpg, pdf,csv or png are allowed`,
    },
  };
}

export async function getFileValidationError({ file }) {
  let isError = true;
  let nameFile = file && file[0] ? file[0].name : "";
  if (file && file.length === 1) {
    const checkImageData = await checkFileUploadCriteria(file);
    const {
      imageType = {},
      imageResolution = {},
      imageSize = {},
    } = checkImageData;

    isError = imageType.errors || imageResolution.errors || imageSize.errors;
    // formik.setTouched(newTouched);
  }
  return Promise.resolve({ isError, nameFile });
}

export function useCheckFileAndUpdateError({
  data,
  setFileUploadErrors,
  fileUploadErrors,
  name,
}) {
  const dataRef = React.useRef(data);
  React.useEffect(() => {
    // dataRef.current = data;
  });

  async function fileCheck(files) {
    const argObj = {
      file: files,
    };
    const errorObj = await getFileValidationError(argObj);

    const { isError } = errorObj;

    if (files === get(dataRef.current, `${name}`, "")) {
      // because file check is asynchronous,
      // it is necessary to set only the error for the file present in our state, not any stale one.
      if (setFileUploadErrors) {
        setFileUploadErrors({
          ...fileUploadErrors,
          [name]: isError, // "utilityBill.files"
        });
      }
    }
  }
  return fileCheck;
}
