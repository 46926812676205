import React, { useState } from "react";
import get from "lodash/get";
import classnames from "classnames";
import { getPinCodeInfo } from "../../../common/constants/api";
import CommonInput from "../CommonComponent/CommonInput";

function CommonPincode(props) {
  const {
    name,
    showLoading,
    noOnBlurApiCall,
    legendName = "Pincode",
    onChange,
    onBlur,
    type = "text",
    value,
    stylesContainer,
    error,
    errorMsg,
    showLegend,
    fieldsetStyle,
    inputStyle,
    legendStyle,
    touched,
    onKeyUp,
    onKeyDown,
    onPinCodeSucessOrFailure = () => {},
  } = props;

  React.useEffect(() => {
    if (value && value.length === 6) {
      getPinCodeInternal(value);
    }
  }, [value]);

  function getPinCodeInternal(pincodeToSearchFor) {
    getPinCodeInfo(pincodeToSearchFor)
      .then(res => {
        const { isAxiosError } = res;

        // to make sure we are setting for the latest pincode value
        if (pincodeToSearchFor === props.value) {
          const data = get(res, "data[0].PostOffice[0]", null);

          if (!isAxiosError && data) {
            if (onPinCodeSucessOrFailure) {
              onPinCodeSucessOrFailure({
                data: get(res, "data[0].PostOffice[0]", null),
                err: false,
              });
            }
          } else {
            onPinCodeSucessOrFailure({
              data: null,
              err: true,
            });
          }
        }
      })
      .catch(err => {
        if (pincodeToSearchFor === props.value) {
          onPinCodeSucessOrFailure({
            data: null,
            err: true,
          });
        }
      });
  }
  function onBlurPincode(e) {
    if (e.target.value.length === 6) {
      const pincodeToSearchFor = e.target.value;
      getPinCodeInternal(pincodeToSearchFor);
    }
  }

  return (
    <CommonInput
      showLoading={showLoading}
      legendName={legendName}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      type={type}
      name={name}
      value={value}
      error={error}
      errorMsg={errorMsg}
      touched={touched}
      showLegend={showLegend}
      fieldsetStyle={fieldsetStyle}
      legendStyle={legendStyle}
      inputStyle={inputStyle}
      onChange={e => {
        onChange(e);
      }}
      onBlur={e => {
        !noOnBlurApiCall && onBlurPincode(e);
        if (onBlur) {
          onBlur(e);
        }
      }}
      stylesContainer={stylesContainer}
    />
  );
}

export default CommonPincode;
