import React, { Fragment } from "react";

const ProgressBar = ({
  bgcolor,
  completed,
  propContainerStyles,
  propFillerStyles,
  showPercentage,
}) => {
  const containerStyles = {
    height: 30,
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 50,
    border: "1px solid #00ACB8",
  };

  const fillerStyles = {
    height: "90%",
    margin: "2px",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    transition: "width 0.2s ease-in-out",
    textAlign: "right",
    overflow: "hidden",
  };

  return (
    <Fragment>
      {showPercentage ? (
        <div className="">{`${Math.round(completed)}%`}</div>
      ) : null}
      <div
        style={propContainerStyles ? propContainerStyles : containerStyles}
        className="flex flex-col content-center overflow-hidden  "
      >
        <div style={propFillerStyles ? propFillerStyles : fillerStyles}></div>
      </div>
    </Fragment>
  );
};

export default ProgressBar;
