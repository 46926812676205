import React, { useRef } from "react";

import FileUploadImg from "../../../images/icons/file_upload.svg";

function UploadImgComponent({
  loading,
  custom,
  imgIndex,
  onChange,
  children,
  name,
  accept,
  isNewOne,
  docDesign,
  aadhaarDesign,
  disabled,
}) {
  const refInput = useRef(null);

  function onChangeFile(e) {
    e.preventDefault();
    if (e?.target?.value?.length == 0) {
      return;
    }
    const files = e?.target?.files ?? e?.dataTransfer?.files;
    const imageFile = files[0];
    let imgUrl = "";
    if (imageFile) {
      imgUrl = window.URL.createObjectURL(imageFile);
    }

    if (onChange) {
      onChange(imgUrl, files, e, imgIndex);
    }
  }

  return (
    <div
      onDrop={onChangeFile}
      onDragOver={e => e.preventDefault()}
      className={
        isNewOne
          ? `cursor-pointer w-full ${
              docDesign ? ` ${aadhaarDesign ? `px-4 py-4` : `px-10 py-3`} ` : ``
            }`
          : `md:w-full h-full md:flex md:flex-col md:justify-around md:text-center cursor-pointer `
      }
      onClick={() => {
        disabled ? null : refInput.current.click();
      }}
    >
      <input
        ref={refInput}
        type="file"
        name={name}
        onChange={onChangeFile}
        className="hidden"
        accept={accept}
      />
      {!children && (
        <>
          <div
            className="w-50 h-25 bg-white mx-auto flex items-center 
            justify-center border-solid
         items-center text-center border-grey-300 file_type"
          >
            <img
              src={FileUploadImg}
              alt="file_uplaod"
              className="mx-auto h-8 items-center"
            />
          </div>

          <p style={{ color: "#6E6E6E", fontSize: "12px" }} className="mt-2">
            Upload
          </p>
        </>
      )}

      {children && children}
    </div>
  );
}

export default UploadImgComponent;
