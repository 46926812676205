import React, { useState } from "react";
import "./CommonToggleButton.css";
import classnames from "classnames";

function CommonToggleButton({
  name,
  labelName,
  Id,
  labelFor,
  checked,
  onChange,
  disabled = false,
  stylesContainer = {},
  dndLabelStyle,
  yellowStyle,
  greenStyle,
  greenStyleLight,
  yellowStyleLight,
}) {
  return (
    <>
      <div className="flex mb-6" style={stylesContainer}>
        <label
          htmlFor={labelFor}
          className={`flex items-center ${
            disabled ? `cursor-not-allowed` : `cursor-pointer`
          }`}
        >
          <div
            className={`mr-3 ${
              dndLabelStyle
                ? `text-xs text-black-400`
                : `text-gray-700 font-medium font-roboto`
            }`}
          >
            {labelName}
          </div>
          <div className="relative">
            <input
              name={name}
              id={Id}
              type="checkbox"
              className="hidden"
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
            <div
              className={`${
                yellowStyle
                  ? checked
                    ? `toggle__line_ye border-2 border-white`
                    : `toggle__line_ye border-2 border-grey-996`
                  : "toggle__line "
              } h-5 rounded-full shadow-inner ${greenStyle} `}
            ></div>
            <div
              className={` ${
                yellowStyle
                  ? " toggle__dot_ye "
                  : !greenStyleLight
                  ? !yellowStyleLight
                    ? " toggle__dot "
                    : "toggle__dot_li_yellow"
                  : " toggle__dot_li_gr"
              }  absolute w-4 h-4 bg-white rounded-full shadow inset-y-0 left-0`}
            ></div>
          </div>
        </label>
      </div>
    </>
  );
}
export default CommonToggleButton;
