export const numberFormat = number => {
  if (!isNaN(number) && number) {
    let num;
    try {
      num = number
        .toFixed(1)
        .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,")
        .toString()
        .split(".")[0];
    } catch {
      num = number;
    }
    return num;
  }
  return number;
};
export const changeNumberFormat = number => {
  const noOfLakhs = number / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    var currency = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 1,
    }).format(integer);
    return currency;
  }
  if (noOfLakhs >= 1 && noOfLakhs <= 99) {
    const lakhs = roundOf(noOfLakhs);
    isPlural = noOfLakhs > 1;
    displayStr = `${roundOf(lakhs/100)} Cr${isPlural ? "" : ""}`;
  } else if (noOfLakhs >= 100) {
    const crores = roundOf(noOfLakhs / 100);
    displayStr = `${crores} Cr`;
  } else {
    displayStr = roundOf(number);
  }
  return displayStr;
};
