import React, { useState } from "react";
import Modal from "../Modal/modal";
import closeIcon from "../../../images/icons/BookingFormCommercial/close_confirm_modal.svg";
const ConfirmModal = props => {
  const style = {
    // Adding media querry..
    "@media (minWidth: 500px)": {
      width: "395px",
    },
  };
  let [
    confirmModal,
    setConfirmModal,
    headText,
    bodyText,
    bodyTextColor = "text-black-400",
    noClicked,
    yesClicked,
    buttonShow,
    proceedTextShow,
    proceedText = "Are you sure you want to proceed?",
    proceedTextColor="text-red-400",
    newDesign=false,
    newDesignColor=null
  ] = [
    props.confirmModal,
    props.setConfirmModal,
    props.headText || "Walk-in From",
    props.bodyText || "",
    props.bodyTextColor,
    props.noClicked,
    props.yesClicked,
    props.buttonShow,
    props.proceedTextShow,
    props.proceedText,
    props.proceedTextColor,
    props.newDesign,
    props.newDesignColor
  ];
  props.buttonShow == undefined ? (buttonShow = true) : "";
  props.proceedTextShow == undefined ? (proceedTextShow = true) : "";
  return (
    <Modal
      show={confirmModal}
      setShow={setConfirmModal}
      styleContainer={style}
      noCloseBtn={false}
      isNewModal={newDesign ? true : false}
    >
      <React.Fragment>
        {!newDesign ? (
          <div>
            <div className="w-full text-center">
              <div className="font-roboto text-lg font-bold text-black-400 border-b border-grey-200 text-center py-4">
                {headText}
              </div>
              <div
                className={`font-roboto font-normal ${bodyTextColor} text-center py-2 mt-2 normal-case`}
              >
                {bodyText}
              </div>
              {proceedTextShow ? (
                <div
                  className={`font-roboto font-normal ${proceedTextColor} text-sm text-center py-1 mt-1 normal-case`}
                >
                  {proceedText}
                </div>
              ) : null}
            </div>
            {buttonShow ? (
              <div className="w-full flex justify-end mt-8">
                <button
                  className="text-black-100 mr-2 md:px-8 px-10 py-4 md:py-2 border border-grey-200 rounded-md font-roboto font-normal hover:bg-grey-100"
                  onClick={noClicked}
                >
                  No
                </button>

                <button
                  className="bg-tealCol-100 border text-white rounded-md font-roboto font-normal md:px-8 px-10 py-4 md:py-2 hover:bg-green-1100"
                  onClick={yesClicked}
                >
                  Yes
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="w-full">
              <div
                className={`bg-green-2100 ${
                  newDesignColor != null ? newDesignColor : ""
                } w-full flex flex-row justify-between px-5 py-2`}
              >
                <div className="w-full flex flex-col justify-items-start">
                  <div className="font-asap text-lg font-semibold text-white">
                    {headText}
                  </div>
                  <div
                    className={`font-asap font-normal text-white text-xs`}
                    style={{ color: "#FFFFFFB3" }}
                  >
                    {bodyText}
                  </div>
                </div>
                <div
                  className="cursor-pointer my-auto"
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  <img src={closeIcon} alt="close_icon" />
                </div>
              </div>
              {proceedTextShow ? (
                <div
                  className={`${proceedTextColor} font-asap font-normal text-lg pt-8 pb-2 pl-5 pr-20`}
                >
                  {proceedText}
                </div>
              ) : null}
            </div>
            {buttonShow ? (
              <div className="w-full flex justify-end p-6">
                <button
                  className={`h-12 bg-green-2100 ${
                    newDesignColor != null ? newDesignColor : ""
                  } flex items-center justify-center
                  text-white px-7 rounded-md ml-5 mt-5 hover:opacity-75 cursor-pointer`}
                  onClick={yesClicked}
                >
                  Yes
                </button>
                <button
                  className={`h-12 cursor-pointer ${
                    newDesignColor == null
                      ? "border-green-2100"
                      : "border-blue-850"
                  } bg-white ${
                    newDesignColor == null ? "text-green-2100" : "text-blue-850"
                  }
                  flex items-center justify-center border-2 px-7 mt-5 rounded-md hover:opacity-75 ml-5`}
                  onClick={noClicked}
                >
                  No
                </button>
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    </Modal>
  );
};
export default ConfirmModal;
