// stoping users from writing anything otherThan numbers

export const validate = (evt, inputLength) => {
  const theEvent = evt || window.event;

  // Handle paste
  let key;
  let regex;
  if (theEvent.type === 'paste') {
    key = theEvent.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    if (key >= 48 && key <= 57) {
      key = String.fromCharCode(key);
      // ^[0][1-9]{1}[0-9]{9}$
      if (inputLength == 0) regex = /[6-9]|\./;
      else {
        regex = /[0-9]|\./;
      }
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    } else {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
};
